<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
<template>
  <div v-if="items.length" class="container" style="justify-content: center;">
    <div class="text6 t-2 mt-4 mb-4 mtext-center">
      <div class="copyright">
        <div
          v-for="group in groups"
          v-if="group.version"
          :key="group.name"
          class="copyright-item"
        >
          <div class="copyright-column">
            <span class="copyright-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                <path
                  d="M14.972 0c4.196 0 7.769 1.465 10.715 4.393A14.426 14.426 0 0 1 28.9
                    9.228C29.633
                    11.04 30 12.964 30 15c0 2.054-.363 3.978-1.085 5.772a13.77 13.77 0 0
                    1-3.2 4.754
                    15.417 15.417 0 0 1-4.983 3.322A14.932 14.932 0 0 1 14.973 30c-1.982
                    0-3.88-.38-5.692-1.14a15.087
                    15.087 0 0 1-4.875-3.293c-1.437-1.437-2.531-3.058-3.281-4.862A14.71
                    14.71 0 0 1 0
                    15c0-1.982.38-3.888 1.138-5.719a15.062 15.062 0 0 1 3.308-4.915C7.303
                    1.456 10.812
                    0 14.972 0zm.055 2.706c-3.429 0-6.313 1.196-8.652 3.589a12.896 12.896
                    0 0 0-2.72
                    4.031 11.814 11.814 0 0 0-.95 4.675c0 1.607.316 3.156.95 4.646a12.428
                    12.428 0 0
                    0 2.72 3.992 12.362 12.362 0 0 0 3.99 2.679c1.483.616 3.037.924
                    4.662.924 1.607 0
                    3.164-.312 4.675-.937a12.954 12.954 0 0 0 4.084-2.705c2.339-2.286
                    3.508-5.152 3.508-8.6
                    0-1.66-.304-3.231-.91-4.713a11.994 11.994 0 0
                    0-2.651-3.965c-2.412-2.41-5.314-3.616-8.706-3.616zm-.188
                    9.803-2.01
                    1.045c-.215-.445-.477-.758-.79-.937-.312-.178-.602-.268-.87-.268-1.34
                    0-2.01.884-2.01
                    2.652 0 .803.17 1.446.509
                    1.928.34.482.84.724 1.5.724.876 0 1.492-.43 1.85-1.286l1.847.937a4.407
                    4.407 0 0 1-1.634
                    1.728c-.696.42-1.464.63-2.303.63-1.34
                    0-2.42-.41-3.242-1.233-.821-.82-1.232-1.964-1.232-3.428
                    0-1.428.416-2.562 1.246-3.401.83-.84 1.879-1.26
                    3.147-1.26 1.858 0 3.188.723 3.992 2.17zm8.652
                    0-1.983 1.045c-.214-.445-.478-.758-.79-.937-.313-.178-.613-.268-.897-.268-1.34
                    0-2.01.884-2.01
                    2.652 0 .803.17 1.446.51 1.928.338.482.838.724 1.5.724.874 0 1.49-.43
                    1.847-1.286l1.875.937a4.606
                    4.606 0 0 1-1.66
                    1.728c-.696.42-1.455.63-2.277.63-1.357
                    0-2.441-.41-3.253-1.233-.814-.82-1.22-1.964-1.22-3.428
                    0-1.428.415-2.562 1.246-3.401.83-.84
                    1.879-1.26 3.147-1.26 1.857 0 3.18.723 3.965 2.17z"
                />
              </svg>
            </span>
            <span class="copyright-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                <path
                  d="M14.973 0c4.213 0 7.768 1.446 10.66 4.34C28.544
                    7.25 30 10.803 30 15c0
                    4.215-1.43 7.723-4.287 10.526C22.678 28.51 19.098 30
                    14.973 30c-4.054
                    0-7.571-1.474-10.553-4.42C1.474 22.633 0 19.107 0 15S1.474
                    7.34 4.42 4.34C7.313
                    1.446 10.83 0 14.973 0zm.054 2.706c-3.41 0-6.295 1.196-8.652
                    3.589-2.447 2.5-3.67
                    5.402-3.67 8.706 0 3.321 1.214 6.196 3.642 8.624 2.429 2.429
                    5.322 3.642 8.679
                    3.642 3.339 0 6.25-1.222 8.732-3.67 2.358-2.267 3.536-5.133
                    3.536-8.598
                    0-3.41-1.197-6.311-3.589-8.705-2.392-2.392-5.285-3.588-8.678-3.588zm4.018
                    8.57v6.134H17.33v7.286h-4.66V17.41h-1.714v-6.134a.93.93
                    0 01.28-.683.933.933
                    0 01.684-.281h6.161c.25 0 .474.093.67.28a.912.912
                    0 01.294.684zM12.91
                    7.42c0-1.41.696-2.116 2.09-2.116s2.09.705
                    2.09 2.116c0 1.393-.697 2.09-2.09
                    2.09-1.393 0-2.09-.697-2.09-2.09z"
                />
              </svg>
            </span>
            <span v-if="group.sa" class="copyright-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                <path
                  d="M14.973 0c4.196 0 7.75 1.455 10.66 4.366C28.544
                    7.26 30 10.804 30
                    15c0 4.197-1.43 7.714-4.287 10.553C22.696 28.518
                    19.115 30 14.973 30c-4.054
                    0-7.571-1.473-10.553-4.42C1.474 22.634 0 19.108 0
                    15c0-4.088 1.474-7.633
                    4.42-10.633C7.33 1.455 10.848 0 14.973 0zm.054
                    2.706c-3.41 0-6.295 1.205-8.652
                    3.616-2.447 2.483-3.67 5.375-3.67 8.678 0 3.34 1.214
                    6.214 3.642 8.625 2.429
                    2.43 5.322 3.643 8.679 3.643 3.339 0 6.25-1.223
                    8.732-3.67 2.358-2.285 3.536-5.151
                    3.536-8.598
                    0-3.428-1.197-6.321-3.589-8.678-2.375-2.412-5.268-3.616-8.678-3.616zM8.33
                    12.884c.286-1.84 1.026-3.264 2.223-4.273 1.196-1.008
                    2.651-1.513 4.366-1.513
                    2.356 0 4.232.76 5.625 2.277 1.393 1.517 2.09 3.464
                    2.09 5.839 0 2.304-.724
                    4.219-2.17 5.745-1.447 1.526-3.321
                    2.29-5.626 2.29-1.696
                    0-3.16-.508-4.392-1.527-1.233-1.018-1.973-2.464-2.224-4.339H12c.09
                    1.822 1.187 2.733 3.295 2.733 1.053 0 1.902-.456
                    2.544-1.366.644-.91.965-2.126.965-3.643
                    0-1.59-.294-2.799-.883-3.63-.59-.83-1.437-1.245-2.545-1.245-2.001
                    0-3.126.884-3.375
                    2.651h1.098l-2.973 2.973-2.973-2.973H8.33z"
                />
              </svg>
            </span>
          </div>
          <div class="copyright-column">
            <p>
              {{ i18n.license.prefix || "i18n.license.prefix" }}
              <a
                :href="`https://creativecommons.org/licenses/by${
                  group.sa ? '-sa' : ''
                }/${group.version}/`"
                >{{ group.name }}</a
              >
              {{ i18n.license.postfix || "" }}.
            </p>
            <p v-if="group.ownersCount">
              {{
                group.ownersCount > 1
                  ? i18n.owner.plural || "i18n.website.plural"
                  : i18n.owner.singular || "i18n.website.singular"
              }}
              <span v-for="(item, i) in group.items" :key="i">
                <template v-if="i > 0">, </template>
                <a v-if="item.profile" :href="item.profile">{{
                  item.owner
                }}</a> </span
              >.
            </p>
          </div>
        </div>
        <div
          v-for="group in groups"
          v-if="
            group.name !== 'Other' && !group.version && group.licenses.length
          "
          :key="group.name"
          class="copyright-item copyright-item-custom"
        >
          <div class="copyright-column">
            <p>
              {{ i18n.license.prefix || "i18n.license.prefix" }}
              <template v-if="group.licenses[0].url">
                <a :href="group.licenses[0].url">{{
                  group.licenses[0].name
                }}</a>
              </template>
              <template v-else>
                <span>{{ group.licenses[0].name }}</span> </template
              >.
            </p>
            <p v-if="group.ownersCount">
              {{
                group.ownersCount > 1
                  ? i18n.owner.plural || "i18n.website.plural"
                  : i18n.owner.singular || "i18n.website.singular"
              }}
              <span v-for="(item, i) in group.items" :key="i">
                <template v-if="i > 0">, </template>
                <a v-if="item.profile" :href="item.profile">{{
                  item.owner
                }}</a> </span
              >.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VCopyright",
  props: {
    interval: {
      type: Number,
      required: false,
      default: 250,
    },
    licenses: {
      type: Object,
      required: false,
      default: () => ({
        unsplash: "https://unsplash.com/license",
        freepik: "https://www.freepikcompany.com/legal#nav-freepik",
        storyset: "https://storyset.com/terms",
        pexels: "https://www.pexels.com/terms-of-service/",
        pixabay: "https://pixabay.com/service/terms/",
      }),
    },
    i18n: {
      type: Object,
      required: false,
      default: () => ({
        license: {
          prefix: "На данной странице используются медиафайлы по лицензии ",
          postfix: " ",
        },
        website: {
          plural: "This page contains media copyrighted by ",
          singular: "This page contains media copyrighted by ",
        },
        owner: {
          plural: "Авторы: ",
          singular: "Автор: ",
        },
      }),
    },
  },
  data() {
    return {
      lastTickCount: 0,
      items: [],
      groups: {},
      timer: null,
    };
  },
  mounted() {
    this.runInterval();
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  methods: {
    runInterval() {
      if (this.lastTickCount !== this.getPageSize() || !this.items.length) {
        this.updateInformation();
      }
      this.lastTickCount = this.getPageSize();
      this.setNextFrame();
    },
    setNextFrame() {
      this.timer = setTimeout(this.runInterval, this.interval);
    },
    getPageSize() {
      return String(document.documentElement.innerHTML).length;
    },
    updateInformation() {
      this.items = [];
      this.groups = {};
      const licenses = [];
      const selector = "*[data-copyright-name]";
      document.querySelectorAll(selector).forEach((el) => {
        const name = this.getCopyrightName(el);
        if (name && this.isVisible(el)) {
          const item = {
            name,
            url: this.getCopyrightURL(el),
            owner: el.getAttribute("data-copyright-owner") || "",
            profile: el.getAttribute("data-copyright-profile") || "",
            source: el.getAttribute("data-copyright-source") || "",
          };
          const groupName = item.name || "";
          if (typeof this.groups[groupName] === "undefined") {
            const sa = groupName.search(/by-sa/gi) > -1;
            const ver = name.match(/(\d+\.\d+)/gi);
            const version = ver?.[0] || false;
            this.groups[groupName] = {
              name: groupName,
              sa,
              version,
              ownersCount: 0,
              items: [],
              licenses: [],
            };
          }
          if (typeof licenses[groupName] === "undefined") {
            licenses[groupName] = {};
          }
          const ukey = String().concat(name, item.url);
          licenses[groupName][ukey] = { name, url: item.url };
          if (item.owner) {
            this.groups[groupName].ownersCount += 1;
          }
          this.groups[groupName].items.push(item);
          this.items.push(item);
        }
        return el;
      });
      Object.entries(licenses).forEach(([groupName, group]) => {
        this.groups[groupName].licenses = Object.values({ ...group });
      });
    },
    getCopyrightName(element) {
      const name = element.getAttribute("data-copyright-name") || "";
      if (name.search(/^(creative\s+commons|cc\s+by|cc)$/gi) > -1) {
        return "CC BY 4.0";
      }
      if (name.search(/^(cc\s+by)/gi) > -1) {
        return name.toUpperCase();
      }
      if (name.search(this.getGroupedLicenceRegExp()) > -1) {
        return name[0].toUpperCase() + name.slice(1).toLowerCase();
      }
      return name;
    },
    getCopyrightURL(element) {
      const custom = element.getAttribute("data-copyright-url") || "";
      const name = this.getCopyrightName(element);
      return custom || this.getCopyrightURLByLicenseName(name);
    },
    getCopyrightURLByLicenseName(licenseName) {
      const name = String(licenseName).toUpperCase().trim();
      if (name.search(/^(cc)/gi) > -1) {
        const sa = name.search(/sa/gi) > -1 ? "-sa" : "";
        const match = name.match(/(\d+\.\d+)/gi);
        const ver = match?.[0] || "4.0";
        return `https://creativecommons.org/licenses/by${sa}/${ver}/`;
      }
      const typeExp = this.getGroupedLicenceRegExp();
      const type = name.match(typeExp) || false;
      if (type) {
        return this.licenses[String(type).toLowerCase()];
      }
      return "";
    },
    isVisible(element) {
      const rect = element.getBoundingClientRect();
      return rect.height > 0;
    },
    getGroupedLicenceRegExp() {
      const list = Object.keys(this.licenses).join("|");
      return RegExp(`^(${list})`, "gi");
    },
  },
};
</script>

<style lang="scss">
@import "../styles/_variables.scss";

.copyright {
  margin-top: -40px;
  text-align: center;

  p {
    margin: 0;
    font-size: inherit;
  }

  a {
    margin: 0;
    text-decoration: underline;
  }

  &-icon {
    display: inline-block;
    width: 20p;
    height: 20px;
    fill: currentColor;
  }

  &-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: stretch;
  }

  @media screen and (max-width: $breakpoint) {
    margin-top: -50px;

    &-item {
      display: block;
    }
  }
}
</style>
